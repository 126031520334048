/* styles.css */
.gray-background {
    background-color: #f0f0f0; /* Gris claro */
}

.table-small-font .ant-table-cell {
    font-size: 4px; /* Tamaño de fuente más pequeño */
}

.table-narrow .ant-table-cell {
    padding: 2px 8px; /* Ajusta el padding según sea necesario */
}
