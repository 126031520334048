.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Color de fondo semi-transparente */
    backdrop-filter: blur(20px); /* Efecto de desenfoque */
    z-index: 999; /* Asegura que el fondo difuminado esté por debajo del modal */
  }
  